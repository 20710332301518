/* src/App.css */

body {
  font-family: "Rubik", sans-serif;
  margin: 0;
  background-color: #5956e9 !important;
  padding: 0;
  position: relative;
  overflow-x: hidden;
}
body::before {
  content: "";
  position: absolute;
  top: 0; /* Adjust the position as needed */
  right: 0; /* Adjust the position as needed */
  width: 300px; /* Adjust the size as needed */
  height: 450px; /* Adjust the size as needed */
  background-color: #5956e9;
  border-top-left-radius: 150px; /* Creates the curve on the left side */
  border-bottom-left-radius: 250px; /* Creates the curve on the left side */
  z-index: -1;
}

#mainheader {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}

.container {
  width: 100%;
  padding: 20px;
  border-radius: 12px;
  background: white;
}

.title {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.input-field,
.color-picker,
.generate-btn {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: #adadad !important;

  border-radius: 8px;
}

.input-field {
  border: 1px solid #ccc;
}

.generate-btn {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.generate-btn:hover {
  background-color: #0056b3;
}

.qr-result {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.qr-result img {
  max-width: 100%;
  height: auto;
}

.detail {
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
}
hr {
  border: none;
  height: 1px; /* Or any height you prefer */
  width: 95%;
}

/* Alternatively, specify the border color directly */
hr {
  border: 1px solid #5c5a5a; /* Use desired color and adjust the width as needed */
}
